import { Routes, Route } from 'react-router-dom'
import React from 'react'
import About from './components/about'
import Contact from './components/contact'
import HowToBuy from './components/how-to-buy'
import Categories from './components/categories'
import Products from './components/products'
import Product from './components/product'
import RedirectToCategories from './components/redirect-to-categories'
import 'bootstrap/dist/css/bootstrap.min.css'
import './global.css'
import './utils/index'
import ComingSoon from './components/coming-soon'

// todo: remove coming soon page

function App() {
  return (
    <Routes>
      <Route path="*" element={<ComingSoon />} />
      {/* <Route exact path="/" element={<Categories />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/how-to-buy" element={<HowToBuy />} />
      <Route exact path="/products" element={<RedirectToCategories />} />
      <Route exact path="/products/:category" element={<Products />} />
      <Route
        exact
        path="/products/:category/:productId"
        element={<Product />}
      />
      <Route path="*" element={<RedirectToCategories />} /> */}
    </Routes>
  )
}

export default App
